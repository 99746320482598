import React from 'react'
import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import MetaHeader from '../components/MetaHeader'

const ContactSuccess = () => (
  <Layout>
    <MetaHeader title="Success" hidden />

    <PageWrapper>
      <section>
        <h1>Message Sent</h1>
        <p>
          Thank you for contacting us. We will get back to you as soon as
          possible!
        </p>
      </section>
    </PageWrapper>
  </Layout>
)
export default ContactSuccess
